import i18n from '@/i18n'
import { RootState } from '@/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

export enum MEDIA_TYPE {
  TIKTOK,
  YOUTUBE,
  KWAI,
  INSTAGRAM
}

export interface User {
  // 地区
  region: string
  /**
   * 头像
   */
  avatar: string
  /**
   * 联系邮箱
   */
  contactEmail: string
  /**
   * 联系电话
   */
  contactNumber: string
  /**
   * 姓
   */
  familyName: string
  /**
   * 名
   */
  firstName: string
  /**
   * 用户id
   */
  userId: string
  userMedias: UserMedia[]
  influencerId: string
}

export interface UserMedia {
  id: number
  /**
   * 用户id
   */
  userId: string
  /**
   * 0=tiktok, 1=youtube, 2=kwai, 3=instagram
   */
  mediaType: MEDIA_TYPE
  /**
   * 社媒链接
   */
  url: string
  /**
   * 社媒用户名
   */
  name: string
}

export interface GlobalState {
  lang: string
  isHybrid: boolean
  /**
   * 是否登录。是 - true, 否 - false
   *
   * default: false
   */
  isLogin: boolean
  /**
   * 判断是否为新用户。false - 新用户 true - 旧用户
   *
   * default: false
   */
  isFramote: boolean
  // account
  accountInfo: Partial<User>
}

const initialState: GlobalState = {
  lang: i18n.fallbackLng,
  isHybrid: false,
  isLogin: false,
  isFramote: false,
  accountInfo: {}
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload
    },
    setIsHybrid: (state, action: PayloadAction<boolean>) => {
      state.isHybrid = action.payload
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload
    },
    setIsFramote: (state, action: PayloadAction<boolean>) => {
      state.isFramote = action.payload
    },
    setAccountInfo: (state, action: PayloadAction<User>) => {
      state.accountInfo = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action: any) => {
      return {
        ...state,
        ...action.payload.global
      }
    })
  }
})

export const { setLang, setIsHybrid, setIsLogin, setIsFramote, setAccountInfo } = globalSlice.actions

export const selectGlobal = (state: RootState) => state.global

export default globalSlice.reducer
