import axios, { AxiosInstance } from 'axios'
import { storagePrefix } from '.'
import Cookies from 'universal-cookie'
import { IncomingMessage } from 'http'
import https from 'https'

const baseURL = `${process.env.NEXT_PUBLIC_HOST}${process.env.NEXT_PUBLIC_BASE_PATH}`
const timeout = 60000

function setInterceptorsRes(ins: AxiosInstance) {
  ins.interceptors.response.use(
    response => {
      const json = response.data

      // 0成功 -1失败
      if (json.code === 0) {
        return json
      }

      const err: any = new Error(json.message)
      err.response = json
      err.status = response.status
      err.code = json.code
      // return Promise.reject(err)
      return Promise.reject({
        code: json.code,
        message: json.message
      })
    },
    error => {
      return Promise.reject(error)
    }
  )
}

const instance = axios.create({
  baseURL,
  timeout
})

// 拦截器
setInterceptorsRes(instance)

instance.interceptors.request.use(
  config => {
    const cookies = new Cookies()
    const accessToken = cookies.get(storagePrefix('token'))

    const { headers = {} } = config
    headers.Authorization = `Bearer ${accessToken}`
    return {
      ...config,
      headers
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export const serverIO = (req: IncomingMessage | undefined) => {
  const serverAxios = axios.create({
    baseURL,
    timeout
  })

  // 拦截器
  setInterceptorsRes(serverAxios)

  serverAxios.interceptors.request.use(
    config => {
      let cookies
      if (process.browser) {
        cookies = new Cookies()
      } else {
        const cookieStr = req?.headers.cookie
        cookies = new Cookies(cookieStr)
      }
      const accessToken = cookies.get(storagePrefix('token'))

      const { headers = {} } = config
      headers.Authorization = `Bearer ${accessToken}`

      const httpsAgent = new https.Agent({ rejectUnauthorized: false })
      return {
        ...config,
        headers,
        httpsAgent
      }
    },
    error => {
      return Promise.reject(error)
    }
  )

  return serverAxios
}

export default instance
