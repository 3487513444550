import { configureStore, ThunkAction, Action, ThunkDispatch, AnyAction } from '@reduxjs/toolkit'

import { createWrapper } from 'next-redux-wrapper'
import counterReducer from '../pagesFolder/about/redux'
import globalReducer from './global'

export function makeStore() {
  return configureStore({
    reducer: {
      counter: counterReducer,
      global: globalReducer
    }
  })
}

export type AppStore = ReturnType<typeof makeStore>
export type AppDispatch = AppStore['dispatch']

export type RootState = ReturnType<AppStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

// https://github.com/kirill-konshin/next-redux-wrapper/issues/207#issuecomment-710063652
// 在 getInitialProps 使用 dispatch 的类型定义
export type AppThunkDispatch = ThunkDispatch<RootState, void, AnyAction>

export const wrapper = createWrapper<AppStore>(makeStore)
