export const storagePrefix = (val: string) => `mobiuspace.framote.${val}`

function getSafeHostname(hostname: string) {
  if (typeof hostname === 'string' && hostname.match(/^[a-zA-Z0-9\u4e00-\u9fa5\-\.]+$/)) {
    return hostname
  } else {
    return ''
  }
}

export function getCookieTopLevelDomain(hostName?: string, testFlag?: string) {
  const hostname = hostName || ''
  // const hostname = hostName || location.hostname
  const testflag = testFlag || 'domain_test'

  const new_hostname = getSafeHostname(hostname)

  const splitResult = new_hostname.split('.')
  if (Array.isArray(splitResult) && splitResult.length >= 2 && !/^(\d+\.)+\d+$/.test(new_hostname)) {
    let domainStr = '.' + splitResult.splice(splitResult.length - 1, 1)
    while (splitResult.length > 0) {
      domainStr = '.' + splitResult.splice(splitResult.length - 1, 1) + domainStr
      const testStr = testflag + '=true; path=/; domain=' + domainStr

      if (testStr.indexOf(testflag + '=true') !== -1) {
        return domainStr
      }
    }
  }

  return ''
}

export const isBrowser = () => !!(typeof window !== 'undefined' && window.document && window.document.createElement)
