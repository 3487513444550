import { FC, PropsWithChildren } from 'react'
import { UseRequestProvider } from '@ahooksjs/use-request'
import io from '@/utils/io'
import { useAppDispatch } from '@/store/hooks'
import { setIsLogin } from '@/store/global'

type UseRequestProviderWrapper = PropsWithChildren

const UseRequestProviderWrapper: FC<UseRequestProviderWrapper> = ({ children }) => {
  const dispatch = useAppDispatch()

  return (
    <UseRequestProvider
      value={{
        requestMethod: io,
        onError: (e: Error) => {
          // 这里使用了全局配置onError,对 401 的情况做唤起登录
          console.log('onError >>>>>>', e.message)
          if (e.message.includes('401')) {
            dispatch(setIsLogin(false))
          }
        }
      }}
    >
      {children}
    </UseRequestProvider>
  )
}

export default UseRequestProviderWrapper
