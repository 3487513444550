import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useRouter } from 'next/router'

import { useAppSelector } from '@/store/hooks'
import { selectGlobal } from '@/store/global'

type AuthenticatedProps = PropsWithChildren

const Authenticated: FC<AuthenticatedProps> = ({ children }) => {
  const { isLogin } = useAppSelector(selectGlobal)
  const router = useRouter()
  const { needLogin = 'true' } = router.query

  useEffect(() => {
    if (!(needLogin !== 'false')) return

    if (!isLogin) {
      router.push('/login')
    }
  }, [isLogin, needLogin])

  return <>{children}</>
}

export default Authenticated
