import React, { ReactElement, ReactNode } from 'react'
import App, { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import I18nProvider from 'next-translate/I18nProvider'

import { getCookie, setCookie } from 'cookies-next'
// import qs from 'qs'

// import configs from '@/configs'
import i18n, { NEXT_LOCALE } from '@/i18n'
import { wrapper } from '@/store'
import { setAccountInfo, setIsFramote, setIsLogin, setLang, User } from '@/store/global'
import { serverIO } from '@/utils/io'
import { isBrowser } from '@/utils'
// import sa from '@/utils/sa'

import Authenticated from '@/components/Authenticated'
import UseRequestProviderWrapper from '@/components/UseRequestProviderWrapper'

import '../styles/reset.css'
import 'antd-mobile/bundle/css-vars-patch.css'
// import 'antd-mobile/dist/antd-mobile.css'
import '../styles/index.scss'

export type NextPageWithLayout = AppProps['Component'] & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = { lang: string; dispatch: any } & AppProps<{
  ns: string
  pathname: string
}> & {
    Component: NextPageWithLayout
  }

class MyApp extends App<AppPropsWithLayout> {
  public static getInitialProps = wrapper.getInitialAppProps(store => async (appContext: AppContext) => {
    const { router, ctx } = appContext

    const { lang: queryLang, needLogin } = router.query

    let lang = ''

    // 语言推导规则
    if (!process.browser) {
      // server: query > browser > default
      // @ts-ignore
      const browserLang = ctx.req?.acceptsLanguages(...i18n.supportedLngs)
      if (queryLang) {
        if (i18n.supportedLngs.includes(queryLang as string)) {
          lang = queryLang as string
        } else {
          // 不在可支持的语言列表时，降级为默认语言
          lang = i18n.fallbackLng
        }
      } else if (browserLang) {
        lang = browserLang
      } else {
        lang = i18n.fallbackLng
      }
      setCookie(NEXT_LOCALE, lang, { req: ctx.req, res: ctx.res })
    } else {
      // client: cookies > default
      const cookiesLang = getCookie(NEXT_LOCALE) as string
      if (cookiesLang) {
        lang = cookiesLang
      } else {
        lang = i18n.fallbackLng
      }
    }
    store.dispatch(setLang(lang))

    // cookies 获取 token
    // 接口请求个人信息
    // 设置登录态
    if (!process.browser) {
      // query.needLogin === 'false' 判断为新用户
      if (needLogin !== 'false') {
        try {
          // 只有老用户能请求成功 获取个人信息
          // 老用户设置 isLogin = true
          const { data: userInfo } = await serverIO(ctx.req).get('/ug-framote/user')
          store.dispatch(setAccountInfo(userInfo as unknown as User))
          store.dispatch(setIsLogin(true))
          store.dispatch(setIsFramote(true))
        } catch (e: any) {
          // 新用户不能获取个人信息
          // status = 401
          // 新用户设置 isLogin = false
          console.log('e', e.message)
          store.dispatch(setIsLogin(false))
          store.dispatch(setIsFramote(false))
        }
      }
    }

    return {
      pageProps: {
        // https://nextjs.org/docs/advanced-features/custom-app#caveats
        ...(await App.getInitialProps(appContext)).pageProps,
        // Some custom thing for all pages
        pathname: ctx.pathname
      },
      lang
    }
  })

  async componentDidMount() {
    // const { sensorDebug } = qs.parse(window.location.search, {
    //   ignoreQueryPrefix: true
    // })
    // 暂时屏蔽 sensor
    // sa.init({
    //   server_url: configs.sensor.server_url,
    //   is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
    //   use_client_time: true,
    //   send_type: 'beacon',
    //   heatmap: {
    //     //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    //     clickmap: 'default',
    //     //是否开启触达注意力图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    //     scroll_notice_map: 'default'
    //   },
    //   // 通过 sensorDebug=true 参数打开调试模式
    //   debug_mode: sensorDebug === 'true' ? true : false
    // })
  }

  // 动态注入语言包
  namespaces = () => {
    const { lang, pageProps } = this.props

    const obj: any = {
      common: require(`../i18n/locales/${lang}.json`)
    }

    const ns = pageProps.ns

    if (ns) {
      obj[ns] = require(`../pagesFolder/${ns}/i18n/${lang}.json`)
    }

    return obj
  }

  render() {
    const { Component, pageProps, lang } = this.props
    const namespaces = this.namespaces()

    // Use the layout defined at the page level, if available
    const getLayout = Component.getLayout ?? (page => page)

    return (
      <>
        <Head>
          <title>Framote</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Head>
        {isBrowser() && location.search.includes('vconsole') && (
          <Script
            src="https://unpkg.com/vconsole@latest/dist/vconsole.min.js"
            onLoad={() => {
              // @ts-ignore
              new window.VConsole()
            }}
          />
        )}
        <I18nProvider lang={lang} namespaces={namespaces}>
          <UseRequestProviderWrapper>
            <Authenticated>{getLayout(<Component {...pageProps} />)}</Authenticated>
          </UseRequestProviderWrapper>
        </I18nProvider>
      </>
    )
  }
}

export default wrapper.withRedux(MyApp)
